import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src430903205/src/dashbot-home-gatsby/src/layouts/blog/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <span className="s1">
  Dashbot, the leading conversational analytics platform, entered into a
  strategic partnership with Recimē, an enterprise chatbot building platform.
  The partnership will provide enterprise brands building conversational
  products on Recimē the ability to track performance and iterate their bots by
  leveraging Dashbot’s advanced conversational analytics.{" "}
    </span>
    <span className="s1">
  Brands and agencies alike have been increasingly extending their products
  across emerging conversational platforms including Facebook Messenger, Amazon
  Echo, and Google Home. Chatbots create a direct channel for consumers to
  connect with major brands and interact with their products. More importantly,
  enterprise companies such as PayPal, SurveyMonkey, Marriot Hotels, and Intuit
  have all invested in expanding their brands and products onto Facebook
  Messenger and other conversational platforms, paving the way for
  conversational commerce.{" "}
    </span>
    <span className="s1">
  Recimē enables enterprise brands to build highly customizable, advanced
  chatbots to reach customers on the channels they use. Dashbot has provided
  cross platform analytics for enterprise brands and startups building
  conversational interfaces since 2016, and has processed more than 23 billion
  messages to date. The partnership includes the option for bot builders on the
  Recimē platform to seamlessly integrate Dashbot analytics into any bot. This
  integration provides a complete suite of Dashbot analytics to Recimē customers
  for free. For additional advanced analytics, customers can choose to upgrade
  to the premium or enterprise editions of Dashbot.{" "}
    </span>
    <span className="s1">
  Mehfuz Hossain, co-founder and CEO at Recimē, shares his thoughts on why he
  selected Dashbot as a preferred analytics partner: “Dashbot is the leader in
  conversational analytics. By providing an in-depth chatbot overview, it gives
  businesses the required insights to improve user engagement and build the best
  possible voice and chatbot experience.”
    </span>
    <span className="s1">
  Arte Merritt, co-founder and CEO at Dashbot, shares his support for the new
  partnership: “Platforms like Recimē make it easy to build compelling
  conversational interfaces, and with this simple integration with Dashbot
  analytics, customers will have a full suite of tools to build, optimize, learn
  and iterate on their applications. I’m really excited to see what our
  customers create.”
    </span>
    <span className="s1">
  For more information on pricing, documentation, and details on how to get
  started with Recimē, please see here: [
  <span className="s2">https://docs.recime.io/third-party-analytics.html</span>
  ](https://docs.recime.io/third-party-analytics.html)
    </span>
    <h2><span className="s1">{`About `}<a parentName="h2" {...{
          "href": "https://recime.io"
        }}><span className="s2">{`Recime`}</span></a></span></h2>
    <p><em parentName="p"><span className="s1">{`Recimē is an enterprise chatbot platform. It provides the bot building tools, cloud infrastructure, chatbot framework, AI and analytics to easily build chatbots. The platform is powerful and easy to use, offering brands and developers the simplest way to create chatbots.`}</span></em></p>
    <h2><span className="s3">{`About `}<a parentName="h2" {...{
          "href": "https://www.dashbot.io/"
        }}><span className="s2">{`Dashbot`}</span></a></span></h2>
    <p><em parentName="p"><span className="s1">{`Dashbot is a conversational analytics platform that enables developers and brands to increase engagement, acquisition, and monetization through actionable data and tools. In addition to traditional analytics like engagement and retention, we provide bot and voice specific metrics including sentiment analysis, conversational analytics, and full chat session transcripts. We also have tools to take action on the data, like our live-person takeover, broadcast messaging, and goal funnels. `}</span></em></p>
    <p><a parentName="p" {...{
        "href": "https://www.dashbot.io/contact/sales?ref="
      }}>{`Contact us for a demo`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      